import React from "react"
import { Link } from "gatsby"

import RightIcon from "../../../assets/icons/chevron-right.svg"
import LaptopIcon from "../../../assets/icons/laptop.svg"
import MobileIcon from "../../../assets/icons/mobile.svg"
import IdeaIcon from "../../../assets/icons/light-bulb.svg"

const WhatWeDo = () => {
  return (
    <section id="whatwedo" className="mt-5 pt-5">
      <div className="container text-center">
        <div className="row">
          <div className="col-lg-9 m-auto">
            <h2>What Can We Do For You?</h2>
            <p className="text-muted fw-l pb-4">
              We specialize in web and mobile application development for
              startups. Our goal is to bring your vision to life through
              building applications that deliver a beautiful and functional user
              experience.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 mb-5">
            <IdeaIcon className="pb-2" />
            <h5 className="text-uppercase">Startup Solutions</h5>
            <p className="text-muted fw-l">
              We help you reduce your time and financial risk through idea
              validation. From idea to MVP to fully polished product, we partner
              with you every step of the way.
            </p>
            <Link
              to="/services/startup-solutions/"
              className="btn btn-link text-uppercase font-weight-bold"
            >
              <span>Learn more</span> <RightIcon />
            </Link>
          </div>
          <div className="col-sm-6 col-md-4 mb-5">
            <LaptopIcon className="pb-2" />
            <h5 className="text-uppercase">Web Applications</h5>
            <p className="text-muted fw-l">
              Any browser, anywhere. We develop full-stack web apps that run on
              all devices with modern browsers. Your app will be built with
              clean, tested and scalable code.
            </p>
            <Link
              to="/services/web-development/"
              className="btn btn-link text-uppercase font-weight-bold"
            >
              <span>Learn more</span> <RightIcon />
            </Link>
          </div>
          <div className="col-sm-6 col-md-4 mb-5">
            <MobileIcon className="pb-2" />
            <h5 className="text-uppercase">Mobile Applications</h5>
            <p className="text-muted fw-l">
              We develop full-stack iOS and Android applications with an intense
              focus on user experience. Your app will be compatible with both
              smartphones and tablets.
            </p>
            <Link
              to="/services/mobile-development/"
              className="btn btn-link text-uppercase font-weight-bold"
            >
              <span>Learn more</span> <RightIcon />
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhatWeDo
