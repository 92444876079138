import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/common/PageHeader"
import WhoWeAre from "../components/pages/about/Who-We-Are"
import WhatWeDo from "../components/pages/about/What-We-Do"
import HowWeAreDifferent from "../components/pages/about/HowWeAreDifferent"
import Contact from "../components/pages/index/Contact"

const ApproachPage = () => (
  <StaticQuery
    query={graphql`
      {
        aboutus: file(relativePath: { eq: "about-us-hero.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="About Us"
          keywords={[`about us`]}
          description="Tactic Apps was built from the ground up to provide exceptional value to startups seeking web and mobile development services. Learn more about who we are and what we do."
        />
        <PageHeader
          image={data.aboutus.childImageSharp.gatsbyImageData}
          title="About Us"
          subTitle="Learn more about who we are and what we do"
          formLocation="onPage"
        />
        <main>
          <WhoWeAre />
          <WhatWeDo />
          <HowWeAreDifferent />
          <Contact
            heading={
              <>
                Want to learn more or{" "}
                <span className="text-primary">hire us</span> for your project?
              </>
            }
          />
        </main>
      </Layout>
    )}
  />
)

export default ApproachPage
