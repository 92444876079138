import React from "react"
import { Link } from "gatsby"

import RightIcon from "../../../assets/icons/chevron-right.svg"
import ChatIcon from "../../../assets/icons/chat.svg"
import OutDatedIcon from "../../../assets/icons/back-in-time.svg"
import AgileIcon from "../../../assets/icons/playlist_add_check.svg"
import AppManagementIcon from "../../../assets/icons/code.svg"
import SolutionIcon from "../../../assets/icons/sync.svg"
import SearchIcon from "../../../assets/icons/magnifying-glass.svg"
import CostIcon from "../../../assets/icons/credit.svg"
import StarIcon from "../../../assets/icons/star.svg"

const HowWeAreDifferent = () => (
  <section id="HowWeAreDifferent" className="pb-5 my-5">
    <div className="container">
      <div className="row mb-4">
        <div className="col-lg-9 m-auto text-center">
          <h2>How We Are Different</h2>
          <p className=" text-muted fw-l">
            Here's why companies trust Tactic Apps with their application
            development and how we differentiate ourselves from the competition.
          </p>
        </div>
      </div>
      <div className="row text-center">
        <div className="num-series col-md-4 mb-5">
          <div className="d-flex flex-column">
            <div className="num">
              <div className="decor" />
              01
            </div>
            <div>
              <h5 className="text-uppercase">Transparency</h5>
            </div>
            <div>
              <p className="text-muted">
                No secrets, no hidden fees. We provide you with full visibility
                into the progress of your application along with a transparent
                hourly cost breakdown on your quote.
              </p>
              <Link
                to="/approach/"
                className="btn btn-link text-uppercase font-weight-bold"
              >
                <span>Learn more</span>
                <RightIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className="num-series col-md-4 mb-5">
          <div className="d-flex flex-column">
            <div className="num">
              <div className="decor" />
              02
            </div>
            <div>
              <h5 className="text-uppercase">Risk Reduction</h5>
            </div>
            <div>
              <p className="text-muted">
                App development can be high risk due to an industry standard of
                low completion rates. We lower your risk by delivering working
                features every two weeks.
              </p>
              <Link
                to="/approach/"
                className="btn btn-link text-uppercase font-weight-bold"
              >
                <span>Learn more</span>
                <RightIcon />
              </Link>
            </div>
          </div>
        </div>
        <div className="num-series col-md-4 mb-5">
          <div className="d-flex flex-column">
            <div className="num">
              <div className="decor" />
              03
            </div>
            <div>
              <h5 className="text-uppercase">Local Support</h5>
            </div>
            <div>
              <p className="text-muted">
                We do not outsource communication outside our company. Whether
                you prefer email, phone or live chat, you will always be in
                contact with a key member of our team
              </p>
              <Link
                to="/contact/"
                className="btn btn-link text-uppercase font-weight-bold"
              >
                <span>Contact Us</span>
                <RightIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="row text-center pt-3">
        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <SolutionIcon />
              </div>
              <h5 className="text-uppercase">Complete software creation</h5>
              <span className="text-muted">
                Get development and design in one place.
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <OutDatedIcon />
              </div>
              <h5 className="text-uppercase">Avoid buying outdated software</h5>{" "}
              <span className="text-muted">We only use modern frameworks.</span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <SearchIcon />
              </div>
              <h5 className="text-uppercase">Higher Search Engine Rankings</h5>
              <span className="text-muted">
                Search engines love our optimized code.
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <StarIcon />
              </div>
              <h5 className="text-uppercase">Happy, Engaged End Users</h5>
              <span className="text-muted">
                We focus on your end user's experience.
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <div className="text-center mb-3">
                  <ChatIcon />
                </div>
              </div>
              <h5 className="text-uppercase">Get your questions answered</h5>
              <span className="text-muted">
                We always respond within 24 Hours.
              </span>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <AgileIcon />
              </div>
              <h5 className="text-uppercase">
                Peace of mind with agile management
              </h5>
              <span className="text-muted">
                Agile delivers working features biweekly.
              </span>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <AppManagementIcon />
              </div>
              <h5 className="text-uppercase">
                Simplified Application Management
              </h5>
              <span className="text-muted">
                Our code is clean, tested, and maintainable.
              </span>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3 mb-4">
          <div className="card">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <CostIcon />
              </div>
              <h5 className="text-uppercase">Lower cost and time to market</h5>
              <span className="text-muted">
                We build MVPs to save you time and money.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default HowWeAreDifferent
