import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import RightIcon from "../../../assets/icons/chevron-right.svg"

class WhoWeAre extends Component {
  state = {
    activeButton: "about",
  }

  onClickHandler = (button) => {
    this.setState({ activeButton: button })
  }

  generateNextButton = (label) => (
    <button className="btn btn-info" onClick={() => this.onClickHandler(label)}>
      <span className="text-capitalize">Next Tab: {label} </span>
      <RightIcon />
    </button>
  )

  render() {
    let content, nextButton
    switch (this.state.activeButton) {
      case "about":
        content = (
          <>
            <p className="h2 fw-l">
              We develop <span className="text-primary">web</span> and{" "}
              <span className="text-primary">mobile</span> applications for
              startups.
            </p>
            <p className="text-muted">
              Founded by Ray Bernard in 2018, Tactic Apps was built from the
              ground up to provide exceptional value to startups seeking web and
              mobile development services. This value is derived from our unique
              services that help our clients validate their ideas, get consumer
              feedback, build MVPs and ultimately releasing a product that's
              loved by their end users.
            </p>
            <p className="text-muted">
              We provide this holistic, personalized approach to our global
              audience because it builds mutual success for our clients, and for
              us. This win-win relationship results in better software for our
              clients, grows our portfolio and creates jobs on both sides. We
              firmly believe we're building a brighter future with our clients
              as software development continues to drive the digital age.
            </p>
          </>
        )
        nextButton = this.generateNextButton("our vision")
        break
      case "our vision":
        content = (
          <>
            <p className="h2 fw-l">
              We seek to{" "}
              <span className="text-primary">improve people's lives</span>{" "}
              through technology.
            </p>
            <p className="text-muted">
              At it's core, application development is all about making people
              happy by providing a simpler and better way to connect with each
              other and accomplish tasks. As a software development company, we
              strive to make a difference in the lives of our clients and their
              end users by providing this essential experience.
            </p>
            <p className="text-muted">
              Due to this goal, our vision becomes quite simple. To enrich
              millions of lives around the globe by providing a positive
              experience through the applications we've developed. A well
              executed application can change the world and we're determined to
              contribute to a positive future by using technology to lead the
              charge.
            </p>
          </>
        )
        nextButton = this.generateNextButton("our team")
        break
      case "our team":
        content = (
          <>
            <p className="h2 fw-l">
              We're a{" "}
              <span className="text-primary">geographically diverse</span> team,
              motivated by our client's success.
            </p>
            <p className="text-muted">
              At Tactic Apps we believe that a great customer experience stems
              from a great work experience. Therefore, we have embraced a remote
              office culture where every team member works from home. This
              freedom allows our diverse team to work in a way that's most
              productive for each individual.
            </p>
            <p className="text-muted">
              Through consistent collaboration and agile project management, we
              ensure that everyone is on track and project deadlines are met. We
              have an exceptionally hard working team since every member
              understands that our client's success allows us to do the work we
              love.
            </p>
          </>
        )
        nextButton = (
          <Link to="/contact">
            <button className="btn btn-info">
              <span>Work With Us </span>
              <RightIcon />
            </button>
          </Link>
        )
        break
      default:
        content = null
        break
    }
    return (
      <StaticQuery
        query={graphql`
          {
            whoWeAre: file(relativePath: { eq: "who-we-are.jpg" }) {
              childImageSharp {
                gatsbyImageData(width: 900, layout: CONSTRAINED)
              }
            }
          }
        `}
        render={(data) => (
          <section id="who-we-are" className="my-5">
            <div className="container">
              <div className="row mb-5">
                <div className="col-lg-9 m-auto text-center ">
                  <h2 className="text-capitalize">
                    Who are you partnering with?
                  </h2>
                  <p className="text-muted fw-l">
                    It's important to know and trust the people you'll be
                    partnering with for your project. Let us show you who we are
                    and how we can help you.
                  </p>
                </div>
              </div>
              <div className="row position-relative">
                <div className="square-bg" />
                <div className="col-lg-6">
                  <div className="story-buttons mb-1">
                    <button
                      className={
                        this.state.activeButton === "about"
                          ? "btn btn-link pl-0 active"
                          : "btn btn-link pl-0"
                      }
                      onClick={() => this.onClickHandler("about")}
                    >
                      <span>About Us</span>
                    </button>
                    <button
                      className={
                        this.state.activeButton === "our vision"
                          ? "btn btn-link pl-0 active"
                          : "btn btn-link pl-0"
                      }
                      onClick={() => this.onClickHandler("our vision")}
                    >
                      <span>Our Vision</span>
                    </button>
                    <button
                      className={
                        this.state.activeButton === "our team"
                          ? "btn btn-link active"
                          : "btn btn-link"
                      }
                      onClick={() => this.onClickHandler("our team")}
                    >
                      <span>Our Team</span>
                    </button>
                  </div>
                  <div className="col-12 px-0 d-md-none mb-3">
                    <GatsbyImage
                      image={data.whoWeAre.childImageSharp.gatsbyImageData}
                      alt="Happy Worker"
                      style={{
                        padding: "10px",
                        border: "10px solid #fff",
                        borderRadius: "5px",
                        boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                      }}
                    />
                  </div>
                  {content}
                </div>
                <div className="col-lg-6 d-none d-lg-block mb-3 mt-5">
                  <GatsbyImage
                    image={data.whoWeAre.childImageSharp.gatsbyImageData}
                    alt="Happy Worker"
                    style={{
                      padding: "10px",
                      border: "10px solid #fff",
                      borderRadius: "5px",
                      boxShadow: "0px 5px 10px 0px rgba(113, 62, 90, 0.1)",
                    }}
                  />
                </div>
                <div className="col-12">{nextButton}</div>
              </div>
            </div>
          </section>
        )}
      />
    )
  }
}

export default WhoWeAre
